import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { device, containerGutter } from "../../utils/device.js"
import config from "../../utils/siteConfig.js"
import { AuthorCard } from '.'

const Authors = ({ authors}) => {

    return (
      <Container >
        {authors.map(({node}, i) =>
            // The tag below includes the markup for each post - components/common/PostCard.js
            <AuthorCard key={i} author={node}/>
        )}
      </Container>
    )
}

const Container = styled("div")`
  z-index: 0;
  width: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`



export default Authors
