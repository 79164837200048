const size = {
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "1440px",
};

export const sizeRaw = {
  mobileL: "425",
  tablet: "768",
  laptop: "1024",
  desktop: "1440",
};

export const device = {
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
};

export const containerGutter = {
  margin: "0%",
}
