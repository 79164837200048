import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { device, containerGutter } from "../../utils/device.js"
import config from "../../utils/siteConfig.js"
import {TagText2} from "../../utils/typography.js"


const TagHelperArray = ({tags}) => {

    const parseUrl = function(url){
      var regex = /^(?:\/\/|[^\/]+)*\//
      return  'tag/' + url + '/';
    }

    return (
      <Container>
        {tags && Object.keys(tags).map(function(tag, index) {
          return <MyLink key={tag} to={parseUrl(tags[index].slug)} ><h3>{tags[index].name}</h3></MyLink>
        })}
      </Container>

    )
}

const Container = styled("div")`
  background-color: ${config.color2};
  padding: 2rem 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const MyLink = styled(Link)`
  min-height: ${config.accessabilitySize};
  display: flex;
  align-items: center;
  margin-right: 2rem;
  text-decoration: none;

  >h3{
    color: ${config.textColorLight};
    ${TagText2}

    &:after{
      display:block;
      content: '';
      border-top: transparent 3px;
      border-bottom: solid 3px ${config.textColorLight};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
  }


  &:hover{
    >h3{
      &:after{
        transform: scaleX(1);
      }
    }
  }
`

TagHelperArray.propTypes = {

}

export default TagHelperArray
