import React from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql  } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'

import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { device, containerGutter } from "../../utils/device.js"
import config from "../../utils/siteConfig.js"
import {FooterText} from "../../utils/typography.js"


function LinkPrefix(props){
  const node = props.node
  const url = `/${node.slug}/`

  return (<Center><MyLink to={url} key={node.id} ><Span>{node.title}</Span></MyLink></Center>)
}

function LinkPrefixAuthor(props){
  const node = props.node
  const url = `/author/${node.slug}/`

  return (<Center><MyLink to={url} key={node.id} ><Span>{node.name}</Span></MyLink></Center>)
}

function LinkPrefixNav(props){
  const node = props.node
  const url = node.url

  return (<Center><MyLink to={url} ><Span>{node.label}</Span></MyLink></Center>)
}

const Footer = ({ data}) => {
    const pages = data.pages.edges
    const authors = data.authors.edges
    const navigation = data.navigation.edges[0].node.navigation

    return (
      <Container>
        <Part>
          {authors.map(({ node },i) => (
              // The tag below includes the markup for each post - components/common/PostCard.js
              <LinkPrefixAuthor key={i} node={node}/>
          ))}
        </Part>
        <Part>
          {pages.map(({ node },i) => (
              // The tag below includes the markup for each post - components/common/PostCard.js
              <LinkPrefix key={i} node={node}/>
          ))}
        </Part>
        <Part>
          {navigation.map((navItem, i) => {
              return <LinkPrefixNav key={i} node={navItem}/>
          })}
        </Part>
      </Container>
    )
}

const Center = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 48px;
`

const MyLink = styled(Link)`
  display: block;
  margin: auto;
  text-decoration: none;
  color: inherit;
  ${FooterText}

  >span{
    &:after{
      display:block;
      content: '';
      border-top: transparent 3px;
      border-bottom: solid 3px ${config.textColorLight};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
  }


  &:hover{
    >span{
      &:after{
        transform: scaleX(1);
      }
    }
  }
`

const Span = styled("span")`
  font-size: inherit;
`

const Container = styled("div")`
  background-color: ${config.footerColor};
  color: ${config.textColorLight};
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;

  font-weight: 400;
  padding-top: 2vh;
  padding-bottom: 2vh;

  @media ${device.tablet} {
    flex-flow: row;
    justify-content: space-around;
  }
`

const Part = styled("div")`
  padding-top: 2vh;
  padding-bottom: 2vh;

  min-width: 60%;
  flex: 1 1 auto;
  border-bottom: 2px solid white;
  display: flex;
  flex-flow: nowrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media ${device.tablet} {
    min-width: auto;
    padding-top: 0vh;
    padding-bottom: 0vh;
    border-right: 2px solid white;
    border-bottom: none;
    align-self: stretch;
  }

  &:last-child{
    border: none;
  }
`

export default props => (
  <StaticQuery
    query={graphql`
      query {
        pages: allGhostPage(
            filter: {slug: {ne: "home"}},
            sort: {order: ASC, fields: published_at}
        ) {
            edges {
                node {
                    slug
                    title
                }
            }
          }

        authors: allGhostAuthor{
          edges {
            node {
              slug
              name
            }
          }
        }

        navigation: allGhostSettings {
            edges {
                node {
                  ...GhostSettingsFields
                }
            }
        }
      }

    `}
    render={data => <Footer data={data} {...props} />}
  />
)

Footer.propTypes = {

}
