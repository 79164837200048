import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { device, containerGutter } from "./device.js"

export const HeaderCursive = css`
  font-family: 'Merriweather', 'serif';
  font-weight: 700;
  font-size: 30px;

  @media ${device.mobileL} {
    font-size: 34px;
  }
  @media ${device.tablet} {
    font-size: 38px;
  }
  @media ${device.laptop} {
    font-size: 42px;
  }
  @media ${device.desktop} {
    font-size: 46px;
  }
`;

export const HeaderNormal = css`
  font-family: 'Rubik', 'sans-serif';
  font-weight: 700;
  font-size: 44px;

  @media ${device.mobileL} {
    font-size: 48px;
  }
  @media ${device.tablet} {
    font-size: 52px;
  }
  @media ${device.laptop} {
    font-size: 56px;
  }
  @media ${device.desktop} {
    font-size: 60px;
  }
`;

export const WelcomeText = css`
  font-family: 'Rubik', 'sans-serif';
  line-height: 1.5;
  font-size: 18px;

  @media ${device.mobileL} {
    font-size: 18px;
  }
  @media ${device.tablet} {
    font-size: 20px;
  }
  @media ${device.laptop} {
    font-size: 22px;
  }
  @media ${device.desktop} {
    font-size: 24px;
  }
`;

export const TagText = css`
  font-family: 'Rubik', 'sans-serif';
  line-height: 1;
  font-weight: 700;
  font-size: 16px;

  @media ${device.mobileL} {
    font-size: 18px;
  }
  @media ${device.tablet} {
    font-size: 18px;
  }
  @media ${device.laptop} {
    font-size: 20px;
  }
  @media ${device.desktop} {
    font-size: 22px;
  }
`;

export const TagText2 = css`
  font-family: 'Rubik', 'sans-serif';
  line-height: 1;
  font-weight: 700;
  font-size: 18px;

  @media ${device.mobileL} {
    font-size: 18px;
  }
  @media ${device.tablet} {
    font-size: 20px;
  }
  @media ${device.laptop} {
    font-size: 22px;
  }
  @media ${device.desktop} {
    font-size: 24px;
  }
`;


export const NavbarText = css`
  font-family: 'Rubik', 'sans-serif';
  line-height: 1;
  font-weight: 400;
  font-size: 18px;

  @media ${device.mobileL} {
    font-size: 20px;
  }
  @media ${device.tablet} {
    font-size: 22px;
  }
  @media ${device.laptop} {
    font-size: 24px;
  }
  @media ${device.desktop} {
    font-size: 26px;
  }
`;

export const FooterText = css`
  font-family: 'Rubik', 'sans-serif';
  line-height: 1;
  font-weight: 400;
  font-size: 16px;

  @media ${device.mobileL} {
    font-size: 16px;
  }
  @media ${device.tablet} {
    font-size: 18px;
  }
  @media ${device.laptop} {
    font-size: 18px;
  }
  @media ${device.desktop} {
    font-size: 20px;
  }
`;

export const BodyText = css`
  font-family: 'Rubik', 'sans-serif';
  line-height: 1.5;
  font-size: 14px;

  @media ${device.mobileL} {
    font-size: 14px;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
  @media ${device.desktop} {
    font-size: 18px;
  }
`;

export const BodyTextSmall = css`
  font-family: 'Rubik', 'sans-serif';
  line-height: 1.5;
  font-size: 10px;

  @media ${device.mobileL} {
    font-size: 10px;
  }
  @media ${device.tablet} {
    font-size: 12px;
  }
  @media ${device.laptop} {
    font-size: 12px;
  }
  @media ${device.desktop} {
    font-size: 14px;
  }
`;

export const BodyTextLarge = css`
  font-family: 'Rubik', 'sans-serif';
  line-height: 1.5;
  font-size: 22px;

  @media ${device.mobileL} {
    font-size: 22px;
  }
  @media ${device.tablet} {
    font-size: 24px;
  }
  @media ${device.laptop} {
    font-size: 24px;
  }
  @media ${device.desktop} {
    font-size: 26px;
  }
`;

export const HeaderXXL = css`
  font-family: 'Rubik', 'sans-serif';
  font-weight: 500;
  font-size: 62px;

  @media ${device.mobileL} {
    font-size: 76px;
  }
  @media ${device.tablet} {
    font-size: 90px;
  }
  @media ${device.laptop} {
    font-size: 104px;
  }
  @media ${device.desktop} {
    font-size: 118px;
  }
`;

export const H1 = css`
  font-family: 'Rubik', 'sans-serif';
  font-size: 24px;
  font-weight: 700;

  @media ${device.mobileL} {
    font-size: 24px;
  }
  @media ${device.tablet} {
    font-size: 26px;
  }
  @media ${device.laptop} {
    font-size: 26px;
  }
  @media ${device.desktop} {
    font-size: 28px;
  }
`;

export const H2 = css`
  font-family: 'Rubik', 'sans-serif';
  font-size: 24px;
  font-weight: 700;

  @media ${device.mobileL} {
    font-size: 24px;
  }
  @media ${device.tablet} {
    font-size: 26px;
  }
  @media ${device.laptop} {
    font-size: 26px;
  }
  @media ${device.desktop} {
    font-size: 28px;
  }
`;

export const H3 = css`
  font-family: 'Rubik', 'sans-serif';
  font-size: 24px;
  font-weight: 700;

  @media ${device.mobileL} {
    font-size: 24px;
  }
  @media ${device.tablet} {
    font-size: 26px;
  }
  @media ${device.laptop} {
    font-size: 26px;
  }
  @media ${device.desktop} {
    font-size: 28px;
  }
`;

export const H5 = css`
  font-family: 'Rubik', 'sans-serif';
  font-size: 20px;
  font-weight: 700;

  @media ${device.mobileL} {
    font-size: 20px;
  }
  @media ${device.tablet} {
    font-size: 22px;
  }
  @media ${device.laptop} {
    font-size: 24px;
  }
  @media ${device.desktop} {
    font-size: 24px;
  }
`;

export const HeaderCursiveSmall = css`
  font-family: 'Merriweather', 'serif';
  font-weight: 700;
  font-size: 26px;

  @media ${device.mobileL} {
    font-size: 30px;
  }
  @media ${device.tablet} {
    font-size: 34px;
  }
  @media ${device.laptop} {
    font-size: 38px;
  }
  @media ${device.desktop} {
    font-size: 42px;
  }
`;

export const Koenig = css`

.kg-image {
    max-width: 100%;
}

.kg-image-card, .kg-gallery-card {
  margin: 0 0 1.5em;

  img {
    display: block;
    margin: 0 auto;
    width: auto;
  }
}

.kg-width-wide {
  position: relative;
  width: 85vw !important;
  min-width: 100% !important;
  margin: auto calc(50% - 50vw) !important;
  transform: translateX(calc(50vw - 50%)) !important;
}

.kg-width-full {
  min-width: 100%;
  width: 100vw !important;
  position: relative;
  left: 50% !important;
  right: 50%!important;
  margin-left: -50vw!important;
  margin-right: -50vw!important;
  }

.kg-gallery-container {
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  width: 100vw;
}

.kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.kg-gallery-image {
  img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
  }
}

.kg-gallery-row {
  &:not(:first-of-type) {
    margin: 0.75em 0 0 0;
  }
}


.kg-gallery-image {
  &:not(:first-of-type) {
    margin: 0 0 0 0.75em;
  }
}


.kg-gallery-card + .kg-image-card.kg-width-wide,
.kg-gallery-card + .kg-gallery-card,
.kg-image-card.kg-width-wide + .kg-gallery-card,
.kg-image-card.kg-width-wide + .kg-image-card.kg-width-wide {
  margin: -2.25em 0 3em;
}


/* keep existing <pre> styles for code cards with captions */
.kg-code-card {
  min-width: 100%;
}

.kg-code-card {
  pre {
    margin: 0;
  }
}


figure {
  margin: 4rem 0;
}

figcaption{
  text-align: center;
  font-family: inherit;
  font-weight: 700;
  font-style: inherit;
  margin-top: 2rem;
  font-size: 0.9em;
}



.kg-image-card figcaption, .kg-gallery-card figcaption {
  margin: 0.5em 0 1.5em;
}


.kg-embed-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5em 0 3em;
  min-width: 100%;
}


.kg-embed-card {
  figcaption {
    margin: 0.5em 0 0
  }
}


.kg-embed-card {
  .fluid-width-video-wrapper {
    margin: 0;
  }
}



.kg-image-full {
  &+figcaption {
    padding: 0 1.5em;
  }
}



@media ${device.laptop} {
  .post-full-content,
  .kg-width-full,
  .kg-image {
    width: 100%;
    object-fit: contain;
  }
}



.kg-gallery-container {
  display: flex;
  flex-direction: column;
  margin: 1.5em auto;
  width: 100%;
}


.kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.kg-gallery-image {
  img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
  }
}


.kg-gallery-row:not(:first-of-type) {
  margin: 0.75em 0 0 0;
}


.kg-gallery-image:not(:first-of-type) {
  margin: 0 0 0 0.75em;
}
`;
