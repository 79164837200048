import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { device, containerGutter } from "../../utils/device.js"
import config from "../../utils/siteConfig.js"
import HamburgerMenu from "../../utils/hamburger.js"
import {NavbarText} from "../../utils/typography.js"
/**
* Navigation component
*
* The Navigation component takes an array of your Ghost
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/


const LinkBlock = styled("div")`

  display: inline-block;
  position: relative;
  text-align: center;
  min-height: ${config.accessabilitySize};
  width: 100%;
  transition: opacity .4s ease-in-out;
  color: inherit;
  opacity: ${props => props.show ? "1" : "0"};
  transition-delay: ${props => props.show ? props.delayOut + 's' : props.delayIn + 's'};

  @media ${device.tablet} {
    padding: 0 2rem;
    transition-delay: ${props => props.show ? props.delayIn + 's' : props.delayOut + 's'};
    display: inline-block;
    width: auto;
  }

  `

const Menu = styled("div")`
  margin: 0px;
  min-height: ${config.accessabilitySize};
  display: flex;
  text-decoration: none;
  color: inherit;

  >*{
    margin: auto;
  }
`

const NavLink = styled(Link)`
  margin: 0px;
  min-height: ${config.accessabilitySize};
  display: flex;
  text-decoration: none;
  color: inherit;
  ${NavbarText}

  >span{
    margin: auto;

    &:after{
      display:block;
      content: '';
      border-top: transparent 3px;
      border-bottom: solid 3px ${config.textColorLight};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
  }

  &:hover{
    >span{
      &:after{
        transform: scaleX(1);
      }
    }
  }
`

const TopNavResponsive = styled("div")`
  width: 100%;
  display: block;
  transition: max-height 1.0s ease-out;
  max-height: ${props => props.show ? "100vh" : "0px"};
  overflow-y: hidden;
  position: relative;
  float: right;
  color: inherit;

  @media ${device.tablet} {
    display: inline-block;
    width: auto;
    max-height: 100%;
  }
`

const NavItem = styled("div")`
  padding: 0 2rem;
  display: inline-block;
  position: relative;
  text-align: center;
  min-height: ${config.accessabilitySize};
`

const NavItemLeft = styled(NavItem)`
  float: left;
`

const NavItemRight = styled(NavItem)`
  float: right;
`



const TopNav = styled("div")`
  z-index: 100;
  position: fixed;
  width: 100%;
  color: ${config.textColorLight};
  background-color: ${config.navbarColor};
`

class Navigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {open: false, };
    this.delayFactor = 0.2;
  }

  toggleMenu() {
    let { open } = this.state;
    this.setState({
      open: !this.state.open
    });
  }


  render() {
    return (
      <TopNav>
          <NavItemLeft><NavLink to="/" ><span>MW</span></NavLink></NavItemLeft>
          <NavItemRight>
            <Menu >
              <HamburgerMenu
                    isOpen={this.state.open}
                    menuClicked={this.toggleMenu.bind(this)}
                    width={24}
                    height={16}
                    strokeWidth={2}
                    rotate={0}
                    color='white'
                    borderRadius={0}
                    animationDuration={0.5}
              />
            </Menu>
          </NavItemRight>
          <TopNavResponsive show={this.state.open}>
            {this.props.data.map((navItem, i) => {
                var delayOut = i * this.delayFactor;
                var delayIn = (this.props.data.length - i)  * this.delayFactor;
                return <LinkBlock  key={i} show={this.state.open} delayOut={delayOut} delayIn={delayIn}><NavLink to={navItem.url}><span>{navItem.label}</span></NavLink></LinkBlock>
            })}
          </TopNavResponsive>
        </TopNav>
      )
  }
}

Navigation.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    location: PropTypes.string,
}

export default Navigation
