import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { device, containerGutter } from "../../utils/device.js"
import config from "../../utils/siteConfig.js"
import {HeaderCursive, BodyText, NavbarText} from "../../utils/typography"
import BackgroundImage from 'gatsby-background-image'

const PostCard = ({url, title, text, img}) => {
    const urlReg = url
    var regex = /^(?:\/\/|[^\/]+)*\//
    const relUrl = url.replace(regex, '/')


    return (
      <Container>
        {img ? (
          <BGI fluid={img.childImageSharp.fluid}>
          <FullDivLink to={relUrl} aria-label="Read more about {title}"></FullDivLink>
          <Mark>View Project</Mark>
          <FlexSpaceArountColor>
            <FlexSpaceAround>
            <ArticleHeader >
              <Header2>{title}</Header2>
            </ArticleHeader>
            <ArticleSection>
              <Divider></Divider>
            </ArticleSection>
            <ArticleFooter>
              {text}
            </ArticleFooter>
            </FlexSpaceAround>
          </FlexSpaceArountColor>
        </BGI>
        ) : (
          <div>
          <FullDivLink to={relUrl} aria-label="Read more about {title}"></FullDivLink>
          <Mark>View Project</Mark>
          <FlexSpaceAround>
          <ArticleHeader >
            <Header2>{title}</Header2>
          </ArticleHeader>
          <ArticleSection>
            <Divider></Divider>
          </ArticleSection>
          <ArticleFooter>
            {text}
          </ArticleFooter>
          </FlexSpaceAround>
        </div>
        )}
      </Container>

    )
}

const BGI = styled(BackgroundImage)`
  height: 100%;

  #stacking-context-reset {
    height: 100% !important;
  }
`

const Header2 = styled("h2")`
  ${HeaderCursive}
  color: ${config.textColorLight};
  margin: 0;
`

const ArticleHeader = styled("header")`
  margin: 0;
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition: transform 1s
`

const ArticleSection = styled("section")`
  max-width: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const Divider = styled("hr")`
  width: 100%;
  border: 1px solid ${config.textColorLight};
`

const ArticleFooter = styled("footer")`
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition: transform 1s;
  ${BodyText}
`

const FlexSpaceArountColor = styled("div")`
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
`

const FlexSpaceAround = styled("article")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 2rem;
  transition: transform 1s;
  color: ${config.textColorLight};
`


const FullDivLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-decoration: none; /* No underlines on the link */
  z-index: 10; /* Places the link above everything else in the div */
  background-color: #FFF; /* Fix to make div clickable in IE */
  opacity: 0; /* Fix to make div clickable in IE */
  filter: alpha(opacity=1); /* Fix to make div clickable in IE */
`

const Mark = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${config.textColorHighlight};
  position: absolute;
  padding: 0 1rem;
  height: 48px;
  right: 2rem;
  transform: translateY(-48px);
  transition: 1s transform;
  ${BodyText}
`

const Container = styled("div")`
  text-align: center;
  overflow: hidden;
  position: relative;
  flex: 1 0 calc(99% - ${containerGutter.margin});
  margin: calc(${containerGutter.margin} / 2);
  color: white;

  >div{
      height: 100%;
  }

  @media ${device.tablet} {
    &:nth-of-type(3n + 2){
      flex: 1 0 calc(50% - ${containerGutter.margin});
    }

    &:nth-of-type(3n + 3){
      flex: 1 0 calc(50% - ${containerGutter.margin});
    }
  }

  &:hover ${Mark}{
    transform: translateY(0);
  }

  &:hover ${ArticleHeader}{
    transform: translateY(-2rem);
  }

  &:hover ${ArticleFooter}{
    transform: translateY(2rem);
  }

  &:nth-of-type(4n + 1){
    background-color: ${config.color1};
  }
  &:nth-of-type(4n + 2){
    background-color: ${config.color2};
  }
  &:nth-of-type(4n + 3){
    background-color: ${config.color3};
  }
  &:nth-of-type(4n + 4){
    background-color: ${config.color4};
  }
`



PostCard.propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    img: PropTypes.object
}

export default PostCard
