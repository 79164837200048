import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { device, containerGutter } from "../../utils/device.js"
import config from "../../utils/siteConfig.js"
import {TagText} from "../../utils/typography.js"


const TagHelper = ({tags}) => {

    const parseUrl = function(url){
      var regex = /^(?:\/\/|[^\/]+)*\//
      return url.replace(regex, '/')
    }

    return (
      <Container>
        {tags.map(({node}) => (
          <MyLink key={node.id} to={parseUrl(node.url)} ><h3>{node.name} ({node.count.posts})</h3></MyLink>
        ))}
      </Container>

    )
}

const Container = styled("div")`
  margin: 0rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const MyLink = styled(Link)`
  min-height: ${config.accessabilitySize};
  display: flex;
  align-items: center;
  margin-right: 2rem;
  text-decoration: none;

  >h3{
    color: ${config.textColorGrey};
    ${TagText}

    &:after{
      display:block;
      content: '';
      border-top: transparent 3px;
      border-bottom: solid 3px ${config.textColorGrey};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
  }


  &:hover{
    >h3{
      &:after{
        transform: scaleX(1);
      }
    }
  }
`

TagHelper.propTypes = {

}

export default TagHelper
