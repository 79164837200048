import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { device, containerGutter } from "../../utils/device.js"
import config from "../../utils/siteConfig.js"
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image'
import {HeaderCursive, BodyText} from "../../utils/typography"

const AuthorCard = ({author}) => {

    const url = `/author/${author.slug}/`

    return ([
      <Container>
        <FullDivLink to={url} aria-label="Read more about {author.name}"/>
        <FlexSpaceAround>
        <ArticleHeader >
          <Header2>{author.name}</Header2>
        </ArticleHeader>
        <ArticleSection>
          <Divider></Divider>
        </ArticleSection>
        <ArticleFooter>
          {author.bio}
        </ArticleFooter>
        </FlexSpaceAround>
      </Container>,
      <Container>
      <Image>
        <BackgroundImage fluid={author.localProfileImage.childImageSharp.fluid} style={{ height: `100%` }}>

        </BackgroundImage>
      </Image>
      </Container>
    ])
}



const Image = styled("div")`
  min-height: 300px;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: "";
  background-size: cover;
`

const Header2 = styled("h2")`
  color: ${config.textColorLight};
  ${HeaderCursive}
  margin: 0;

  &:after{
    display:block;
    content: '';
    border-bottom: solid 3px ${config.textColorLight};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
`

const ArticleHeader = styled("header")`
  margin: 0;
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition: text-decoration 1s
`

const ArticleSection = styled("section")`
  max-width: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const Divider = styled("hr")`
  width: 100%;
  border: 1px solid ${config.textColorLight};
`

const ArticleFooter = styled("footer")`
  ${BodyText}
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition: transform 1s
`

const FlexSpaceAround = styled("article")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding:  3rem 1rem;
  transition: transform 1s;
  color: ${config.textColorLight};
`


const FullDivLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-decoration: none; /* No underlines on the link */
  z-index: 10; /* Places the link above everything else in the div */
  background-color: #FFF; /* Fix to make div clickable in IE */
  opacity: 0; /* Fix to make div clickable in IE */
  filter: alpha(opacity=1); /* Fix to make div clickable in IE */
`

const Container = styled("div")`
  text-align: center;
  overflow: hidden;
  position: relative;
  flex: 1 0 calc(99% - ${containerGutter.margin});
  margin: calc(${containerGutter.margin} / 2);
  color: white;

  @media ${device.tablet} {
    flex: 1 0 calc(50% - ${containerGutter.margin});
  }

  &:hover ${Header2}{
    &:after{
      transform: scaleX(1);
    }
  }

  &:nth-of-type(4n + 1){
    background-color: ${config.color1};
  }
  &:nth-of-type(4n + 2){
    background-color: ${config.color2};
  }
  &:nth-of-type(4n + 3){
    background-color: ${config.color3};
  }
  &:nth-of-type(4n + 4){
    background-color: ${config.color4};
  }
`



AuthorCard.propTypes = {
    author: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        excerpt: PropTypes.string.isRequired,
    }).isRequired,
}

export default AuthorCard
