import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import config from '../../utils/siteConfig'
import { device } from "../../utils/device.js"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import {FooterText} from "../../utils/typography"

const Pagination = ({ pageContext }) => {
    const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext

    return (
        <Container>
                <Left hidden={previousPagePath}>
                {previousPagePath && (

                    <MyLink to={previousPagePath} rel="prev">
                            <span>Previous</span>
                    </MyLink>

                )}
                </Left>

            {numberOfPages > 1 && <Pages className="pagination-location"><span>Page {humanPageNumber} of {numberOfPages}</span></Pages>}
            <Right hidden={nextPagePath}>
                {nextPagePath && (

                    <MyLink to={nextPagePath} rel="next">
                            <span>Next</span>
                    </MyLink>

                )}
            </Right>
        </Container>
    )
}

const MyLink = styled(Link)`
  display: flex;
  min-height: ${config.accessabilitySize};
  align-items: center;
  text-decoration: none;
  color: inherit;

  >span{
    display: block;
    margin: auto;
  }
`

const Pages = styled("div")`
  margin-top: 1em;
  display: block;
  border: 6px transparent;
  display: flex;
  min-height: ${config.accessabilitySize};
  align-items: center;
  justify-content: center;
  order: 4;
  width: 100%;

  @media ${device.tablet}{
    margin-top: 0em;
    order: 2;
    width: auto;
  }

  >span{
    display: block;
    margin: 0.5em 1em;
  }
`

const Button = styled("div")`
  visibility: ${props => props.hidden ? 'visible' : 'hidden'};
  display: inline-block;
  border: 6px solid ${config.color1};
  width: 120px;
  order: 1;
  transition: border-color 0.5s, color 0.5s;

  &:hover{
    border-color: ${config.textColorDark};
    color: ${config.textColorHighlight};
  }
`

const Left = styled(Button)`
  order: 1;
`

const Right = styled(Button)`
  order: 3;
`

const Container = styled("div")`
  width: 100%;
  max-width: 600px;
  margin: 3rem auto;
  padding: 0 0rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${FooterText}
  font-weight: 700;

  @media ${device.mobileL}{
    padding: 0 2rem;
  }
`

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired,
}

export default Pagination
