import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { device, sizeRaw } from "../../../utils/device.js"
import config from "../../../utils/siteConfig.js"
import {BodyText, HeaderCursive} from "../../../utils/typography"
import BackgroundImage from 'gatsby-background-image'

class ImageHeader extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
      return (
        <Container>
        {this.props.image ? (
        <Image fluid={this.props.image.childImageSharp.fluid}>
          <ArticleContainer>
            <Article>
              <Header>{this.props.title}</Header>
              <Divider></Divider>
              <Footer><div>{this.props.excerpt}</div></Footer>
            </Article>
          </ArticleContainer>
          </Image>
        ) : (
          <ArticleContainer2>
            <Article>
              <Header>{this.props.title}</Header>
              <Divider></Divider>
              <Footer><div>{this.props.excerpt}</div></Footer>
            </Article>
          </ArticleContainer2>
        )}



        </Container>

      )
    }
}

const Image = styled(BackgroundImage)`
  height: 100%;
  width: 100%;

  #stacking-context-reset {
    height: 100% !important;
  }

`

const Header = styled("h1")`
  margin: 0;
  display: block;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  ${HeaderCursive}
`

const Divider = styled("hr")`
  width: 90%;
  border: 1px solid ${config.textColorLight};
  max-width: 300px;
`

const Footer = styled("footer")`
  display: table;
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition: transform 1s;
  ${BodyText}

  >div{
    display: table-cell;
    text-align: center;
  }
`

const ArticleContainer2 = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ArticleContainer = styled(ArticleContainer2)`
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));

`

const Container = styled("div")`
  color: ${config.textColorLight};
  background-color: ${config.color3};
  width: 100%;
  height: 80vh;

  @media ${device.tablet} {
    height: 60vh;
  }
`

const Article = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  width: 90%;
  max-width: 800px;
`


export default ImageHeader
