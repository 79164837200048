import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

import { device, sizeRaw } from "../../../utils/device.js"
import config from "../../../utils/siteConfig.js"
import {Container, Welcome, Hello, Text, Attention, BottomAction} from "./styled.js"


class FullHeader extends React.Component {

  constructor(props) {
    super(props);
    try {
      this.state = {windowWith: window.innerWidth};
    } catch(e) {
      //console.log(e);
    }
    this.state = {size: "0 0 1280 800" };
    this.state = { dynamicText: config.passionateAbout[0] };
    this.state = {counter: 0};
  }

  handleResize = (event) => {

    this.setState({ windowWith: window.innerWidth })

    if(window.innerWidth < sizeRaw.mobileL){
      this.setState({ size: "0 0 500 800"})
    }
    else if(window.innerWidth < sizeRaw.tablet){
      this.setState({ size: "0 0 700 800"})
    }
    else if(window.innerWidth < sizeRaw.laptop){
      this.setState({ size: "0 0 800 800" })
    }
    else if(window.innerWidth < sizeRaw.desktop){
      this.setState({ size: "0 0 1000 800" })
    }
    else{
      this.setState({ size: "0 0 1280 800" })
    }
  }

  tick() {
    var length = config.passionateAbout.length

    this.setState(prevState => ({
      dynamicText: config.passionateAbout[this.state.counter]
    }));

    this.setState({counter: (this.state.counter + 1) % length})
  }

  componentDidMount() {
    this.tick();
    this.interval = setInterval(() => this.tick(), 5000);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.handleResize);
  }

  render () {
      return (
        <Container>
          <Welcome>
              <Hello>hello,</Hello>
              <Text>
                My name is Markus Wallinger.<br/>
                I am an Austrian based freelance software developer.<br/>
                I am passionate about <Attention>{this.state.dynamicText}</Attention>
              </Text>
          </Welcome>
          <BottomAction>
          Scroll down for more
          </BottomAction>
          <svg
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox= {this.state.size}
            preserveAspectRatio="none"
          >
          <style>
              { `.st0 { fill: ${config.bg7} }
                .st1 { fill: ${config.bg6} }
                .st2 { fill: ${config.bg5} }
                .st3 { fill: ${config.bg4} }
                .st4 { fill: ${config.bg3} }
                .st5 { fill: ${config.bg2} }
                .st6 { fill: ${config.bg1} }
                .st7 { fill: ${config.bg0} }` }
          </style>
          <defs>
              <g id="Path1">
                <path d="M1026,0c36.9,16.4,93,46.7,147.8,101c58.3,57.8,89.8,117.8,106.2,155.8V800H0V0H1026z">
                  <animate  dur="30s"
                            repeatCount="indefinite"
                            attributeName="d"
                            values="
                        M1026,0c36.9,16.4,93,46.7,147.8,101c58.3,57.8,89.8,117.8,106.2,155.8V800H0V0H1026z;

                        M1026,0c42.5,0,120.5,32.6,175.2,86.8c58.3,57.8,62.4,131.9,78.8,169.9V800H0V0H1026z;

                        M1026,0c36.9,16.4,93,46.7,147.8,101c58.3,57.8,89.8,117.8,106.2,155.8V800H0V0H1026z;

                        M1026,0c-6.2,42.6,44.4,78.6,99.1,132.9c58.3,57.8,110.6,127.4,154.9,123.9V800H0V0H1026z;

                        M1026,0c36.9,16.4,93,46.7,147.8,101c58.3,57.8,89.8,117.8,106.2,155.8V800H0V0H1026z;"/>
                </path>
              </g>
              <g id="Path2">
                <path d="M769.4,0c49,20.8,114.2,52.7,185,101c49.5,33.8,119.7,82.5,188.5,165.5c39.7,47.9,97.1,129.1,137.2,246V800H0V0
                    H769.4z">
                      <animate  dur="32s"
                    repeatCount="indefinite"
                    attributeName="d"
                    values="
                            M769.4,0c49,20.8,114.2,52.7,185,101c49.5,33.8,119.7,82.5,188.5,165.5c39.7,47.9,97.1,129.1,137.2,246V800H0V0
                    H769.4z;

                            M769.4,0c49,20.8,136.4,45.5,218.6,70c59.3,17.7,124.8,116.8,154.9,196.5c22,58.2,97.1,129.1,137.2,246V800H0V0
                    H769.4z;

                            M769.4,0c49,20.8,114.2,52.7,185,101c49.5,33.8,119.7,82.5,188.5,165.5c39.7,47.9,97.1,129.1,137.2,246V800H0V0
                    H769.4z;

                            M769.4,0c1.8,51.5,50.5,80.1,121.2,128.4c49.5,33.8,154.4,52.8,216.8,154.9c29.2,47.8,34.5,126.5,172.6,229.2
                    V800H0V0H769.4z;

                            M769.4,0c49,20.8,114.2,52.7,185,101c49.5,33.8,119.7,82.5,188.5,165.5c39.7,47.9,97.1,129.1,137.2,246V800H0V0
                    H769.4z;"/>
                </path>
              </g>
              <g id="Path3">
                <path  d="M513.6,0c43,28.5,105.3,70.2,179.6,122.2c116.2,81.3,175.6,123.1,239.8,177.9
                    c67.2,57.3,130.9,111.6,196.5,196.5c44.1,57,103.5,146.6,150.5,271.7V800H0V0H513.6z">
                      <animate  dur="33s"
                    repeatCount="indefinite"
                    attributeName="d"
                    values="
                            M513.6,0c43,28.5,105.3,70.2,179.6,122.2c116.2,81.3,175.6,123.1,239.8,177.9
                    c67.2,57.3,130.9,111.6,196.5,196.5c44.1,57,103.5,146.6,150.5,271.7V800H0V0H513.6z;

                            M513.6,0c43,28.5,89.9,109.3,179.6,122.2c104.4,15,191.5,50.5,255.8,105.3c67.2,57.3,161.9,163.4,180.5,269
                    c15.9,90.3,15,184.1,150.5,271.7V800H0V0H513.6z;

                            M513.6,0c43,28.5,105.3,70.2,179.6,122.2c116.2,81.3,175.6,123.1,239.8,177.9
                    c67.2,57.3,130.9,111.6,196.5,196.5c44.1,57,103.5,146.6,150.5,271.7V800H0V0H513.6z;

                            M513.6,0c43,28.5,63.7,121.6,138.1,173.6c116.2,81.3,211,85,275.2,139.8c67.2,57.3,58.3,147,123.9,231.9
                    c44.1,57,182.3,97.9,229.2,223V800H0V0H513.6z

                            M513.6,0c43,28.5,105.3,70.2,179.6,122.2c116.2,81.3,175.6,123.1,239.8,177.9
                    c67.2,57.3,130.9,111.6,196.5,196.5c44.1,57,103.5,146.6,150.5,271.7V800H0V0H513.6z;"/>
                </path>
              </g>
              <g id="Path4">
                <path d="M255.2,0c112.6,73.6,207.4,144.6,284.1,206.3c73.8,59.4,122.1,103.3,218.6,191.2
                    c131.6,119.8,156.7,151.1,177,181.4c56.3,84.2,79.5,166.9,90.3,221.1H0V0H255.2z">
                      <animate  dur="31s"
                    repeatCount="indefinite"
                    attributeName="d"
                    values="
                            M255.2,0c112.6,73.6,207.4,144.6,284.1,206.3c73.8,59.4,122.1,103.3,218.6,191.2
                    c131.6,119.8,156.7,151.1,177,181.4c56.3,84.2,79.5,166.9,90.3,221.1H0V0H255.2z;

                            M255.2,0c112.6,73.6,97.3,162.1,204.4,198.4c89.8,30.4,222.1,81,333.6,148.7
                    c170.8,103.5,179.7,185.7,181.4,222.1c4.4,92,39.7,176.6,50.4,230.8H0V0H255.2z;

                            M255.2,0c112.6,73.6,207.4,144.6,284.1,206.3c73.8,59.4,122.1,103.3,218.6,191.2
                    c131.6,119.8,156.7,151.1,177,181.4c56.3,84.2,79.5,166.9,90.3,221.1H0V0H255.2z;

                            M255.2,0c112.6,73.6,110.9,180,187.6,241.7c73.8,59.4,180.5,82.1,277,169.9c131.6,119.8,161.4,180.7,173.5,215
                    c34.5,98.2,52.2,134.5,131.9,173.3H0V0H255.2z

                            M255.2,0c112.6,73.6,207.4,144.6,284.1,206.3c73.8,59.4,122.1,103.3,218.6,191.2
                    c131.6,119.8,156.7,151.1,177,181.4c56.3,84.2,79.5,166.9,90.3,221.1H0V0H255.2z;"/>
                </path>
              </g>
              <g id="Path5">
                <path d="M0,0c49.9,39.8,131,103.6,234,178.9C467.2,349.6,560.8,396,647.2,516.9C687.2,572.8,739,663.3,767.6,800H0V0z">
                      <animate  dur="30s"
                    repeatCount="indefinite"
                    attributeName="d"
                    values="
                            M0,0c49.9,39.8,131,103.6,234,178.9C467.2,349.6,560.8,396,647.2,516.9C687.2,572.8,739,663.3,767.6,800H0V0z;

                            M0,0c49.9,39.8,107.1,139.8,210.1,215.2c233.2,170.7,420.6,135.7,507.1,256.6c39.9,55.8,21.8,191.5,50.4,328.2
                    H0V0z;

                            M0,0c49.9,39.8,131,103.6,234,178.9C467.2,349.6,560.8,396,647.2,516.9C687.2,572.8,739,663.3,767.6,800H0V0z;

                            M0,0c49.9,39.8,143.7,136.4,181.8,210.7c131.7,257.2,167.4,148,349.6,340.7c46,48.7,31,148.7,236.3,248.5H0V0z;

                            M0,0c49.9,39.8,131,103.6,234,178.9C467.2,349.6,560.8,396,647.2,516.9C687.2,572.8,739,663.3,767.6,800H0V0z;"/>
                </path>
              </g>
              <g id="Path6">
                <path  d="M0,255c101.8,57,180,114.7,234.9,159.3c88.3,71.8,154.2,126.3,209.7,220.4c38.3,64.7,57.7,124.6,68.1,165.4H0
                    V255z">
                      <animate  dur="35s"
                    repeatCount="indefinite"
                    attributeName="d"
                    values="
                            M0,255c101.8,57,180,114.7,234.9,159.3c88.3,71.8,154.2,126.3,209.7,220.4c38.3,64.7,57.7,124.6,68.1,165.4H0
                    V255z;

                            M0,255c29.5,92.9,89.7,172.6,217.2,183.2c113.4,9.5,235.2,25.3,284.1,123c39.8,79.6,1.1,198,11.5,238.8H0V255z;

                            M0,255c101.8,57,180,114.7,234.9,159.3c88.3,71.8,154.2,126.3,209.7,220.4c38.3,64.7,57.7,124.6,68.1,165.4H0
                    V255z;

                            M0,255c19.8,109.7,98.6,127.1,153.4,171.7c88.3,71.8,146.2,143.2,201.8,237.2c38.3,64.7,28.6,127.4,157.5,136.2
                    H0V255z;

                            M0,255c101.8,57,180,114.7,234.9,159.3c88.3,71.8,154.2,126.3,209.7,220.4c38.3,64.7,57.7,124.6,68.1,165.4H0
                    V255z;"/>
                </path>
              </g>
              <g id="Path7">
                <path d="M0,511.6c46.7,31.3,83.5,61,110.1,84.1c45.9,39.8,74.7,65.1,102,107c24.5,37.5,37.6,72.8,44.9,97.3H0V511.6z">
                      <animate  dur="33s"
                    repeatCount="indefinite"
                    attributeName="d"
                    values="
                            M0,511.6c46.7,31.3,83.5,61,110.1,84.1c45.9,39.8,74.7,65.1,102,107c24.5,37.5,37.6,72.8,44.9,97.3H0V511.6z;

                            M0,511.6c46.7,31.3,102.1,48.7,149,56.6c59.9,10.2,93.1,72.3,120.4,114.2C293.9,720,276.8,784.2,257,800H0
                    V511.6z;

                            M0,511.6c46.7,31.3,83.5,61,110.1,84.1c45.9,39.8,74.7,65.1,102,107c24.5,37.5,37.6,72.8,44.9,97.3H0V511.6z;

                            M0,511.6C46.7,543,113.6,539.9,140.2,563c45.9,39.8,11.7,112.2,38.9,154c24.5,37.5,70.6,58.5,77.9,83.1H0V511.6
                    z;

                            M0,511.6c46.7,31.3,83.5,61,110.1,84.1c45.9,39.8,74.7,65.1,102,107c24.5,37.5,37.6,72.8,44.9,97.3H0V511.6z;"/>
                </path>
              </g>
          </defs>

          <use x="0" y="0" xlinkHref="#Path1" className="st0" style={{visibility: this.state.windowWith  < sizeRaw.laptop ? "hidden" : "visible"}}/>
          <use x="0" y="0" xlinkHref="#Path2" className="st1" style={{visibility: this.state.windowWith  < sizeRaw.tablet ? "hidden" : "visible"}}/>
          <use x="0" y="0" xlinkHref="#Path3" className="st2" style={{visibility: this.state.windowWith  < sizeRaw.tablet ? "hidden" : "visible"}}/>
          <use x="0" y="0" xlinkHref="#Path4" className="st3" style={{visibility: this.state.windowWith  < sizeRaw.mobileL ? "hidden" : "visible"}}/>
          <use x="0" y="0" xlinkHref="#Path5" className="st4"/>
          <use x="0" y="0" xlinkHref="#Path6" className="st5"/>
          <use x="0" y="0" xlinkHref="#Path7" className="st6"/>
          </svg>
        </Container>

      )
    }
}



export default FullHeader
