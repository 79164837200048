import styled from "@emotion/styled"
import { css, keyframes } from "@emotion/core"
import { device, sizeRaw } from "../../../utils/device.js"
import config from "../../../utils/siteConfig.js"
import {HeaderNormal, WelcomeText} from "../../../utils/typography"

const trackingIn = keyframes`
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`

const trackingInBottom = keyframes`
0%{
  letter-spacing: -0.5em;
  -webkit-transform: translateZ(-700px) translateY(500px);
          transform: translateZ(-700px) translateY(500px);
  opacity: 0;
}
40%{
  opacity: 0.6;
}
100%{
  -webkit-transform: translateZ(0) translateY(0);
          transform: translateZ(0) translateY(0);
  opacity: 1;
}
`

const slideInFromBottom = keyframes`
0%{
  opacity: 0;
  -webkit-transform:translateY(100px);
          transform:translateY(100px);
}
60%{
  opacity: 1;
}
100%{
  -webkit-transform:translateY(0);
          transform:translateY(0);
}
`

export const Hello = styled("h1")`
  color: ${config.textColorHighlight};
  ${HeaderNormal}
  animation: ${trackingIn} 1.2s;
`

export const Text = styled("div")`
  display: inline-block;
  margin-top: 1em;
  ${WelcomeText}
  animation: ${trackingIn} 1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.5s both;
`
export const Attention = styled("div")`
  display: inline-block;
  color: ${config.textColorDark};
`

export const Welcome = styled("div")`
  position: absolute;
  margin-top: 40vh;
  margin-left: 5%;
  color: ${config.textColorLight};
  font-weight: 500;
  width: 90%;
`

export const BottomAction = styled("div")`
  position: absolute;
  bottom: 0.2em;
  color: ${config.textColorLight};
  font-weight: 500;
  width: 100%;
  ${WelcomeText}
  text-align: center;
  animation: ${slideInFromBottom} 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) 5.5s both;
`

export const Container = styled("div")`
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: ${config.bg4};

  @media ${device.mobileL} {
    background-color: ${config.bg5};
  }
  @media ${device.tablet} {
    background-color: ${config.bg6};
  }
  @media ${device.laptop} {
    background-color: ${config.bg7};
  }
  @media ${device.desktop} {
    background-color: ${config.bg8};
  }
`
