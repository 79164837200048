module.exports = {
    siteUrl: `https://mwallinger.com`, // Site domain. Do not include a trailing slash!
    targetAddress: new URL(`https://mwallinger.com`),

    postsPerPage: 8, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `mwallinger.com`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `mwallinger.com - software development`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `MWallinger`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest

    textColorLight: '#fff',
    textColorDark: '#000',
    textColorHighlight: '#FF7445',
    textColorGrey   : '#6E6E6E',

    color1      : '#28559A',
    color2      : '#3778C2',
    color3      : '#21467f',
    color4      : '#1a3764',

    bg1         : '#7ED5EA',
    bg2         : '#63BCE5',
    bg3         : '#4B9FE1',
    bg4         : '#3778C2',
    bg5         : '#28559A',
    bg6         : '#0F2557',
    bg7         : '#150734',
    bg8         : '#000',

    navbarColor : '#1a3764',
    footerColor: '#1a3764',

    passionateAbout: ['software development', 'machine learning', 'skiing', 'web development', 'metro maps', 'graph drawing'],

    contentMaxWidth: '992px',

    accessabilitySize: '48px',

    fontSizeXxl       : "7.2rem",
    fontSizeXl       : "3.5rem",
    fontSizeL        : "2.5rem",
    fontSizeM        : "1.5rem",
    fontSizeS       : "1.35rem",
    fontSizeTextL   : "1.2rem",
    fontSizeTextM   : "1.2rem",
    fontSizeTextS   : "0.8rem",
    fontSizeTextXs   : "0.6rem",

    font1   : "'Rubik', sans-serif",
    font2   : "'Merriweather', serif",
    fontWeight1 : "400",
    fontWeight2 : "700",
}
